(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name footer.controller:FooterCtrl
   *
   * @description
   *
   */
  angular
    .module('footer')
    .controller('FooterCtrl', FooterCtrl);

  function FooterCtrl() {
    var vm = this;
    vm.ctrlName = 'FooterCtrl';
  }
}());
